export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: 'Blog',

  author: 'Paul Ashley',
  description:
    "A personal blog, whose purpose is to capture some thoughts on my experiences and also as a reference to myself for my work.",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
